import { get, post } from "../axios";

export function getChain() {
  const url = "/chain";
  return get(url);
}

export function getBlockNumber(params) {
  const url = "/blockbynumber";
  return post(url, params);
}

export function getBlockHash(params) {
  const url = "/blockbyhash";
  return post(url, params);
}

export function getBlocks(params) {
  const url = "/blocks";
  return post(url, params);
}

export function getTx(params) {
  const url = "/transactionbyhash";
  return post(url, params);
}

export function getTxs(params) {
  const url = "/transactions";
  return post(url, params);
}

export function getTxsOfContract(params) {
  const url = "/transactionsofcontract";
  return post(url, params);
}

export function getTxsOfUser(params) {
  const url = "/transactionsofuser";
  return post(url, params);
}

export function getTxsFromToUser(params) {
  const url = "/transactionsfromtouser";
  return post(url, params);
}

export function getTxDetailsFromToUser(params) {
  const url = "/transactiondetailsfromtouser";
  return post(url, params);
}

export function getPltHolders(params) {
  const url = "/pltholders";
  return post(url, params);
}

export function getPltHolderInfo(params) {
  const url = "/pltholderinfo";
  return post(url, params);
}

export function getPltCreatedAddresses(params) {
  const url = "/pltcreatedaddresses";
  return post(url, params);
}

export function getNfts(params) {
  const url = "/nfts";
  return post(url, params);
}

export function getNftTx(params) {
  const url = "/nfttransactions";
  return post(url, params);
}

export function getNftUsers(params) {
  const url = "/nftusers";
  return post(url, params);
}

export function getNft(params) {
  const url = "/nft";
  return post(url, params);
}

export function getNftTokens1(params) {
  const url = "/nfttokens";
  return post(url, params);
}

export function getNftTokenInfo(params) {
  const url = "/nfttokeninfo";
  return post(url, params);
}

export function getNftHolderOfUser(params) {
  const url = "/nftholdersofuser";
  return post(url, params);
}

export function getNftTokens(params) {
  const url = "/nftholders";
  return post(url, params);
}

export function getNftTokenTx(params) {
  const url = "/nfttokentransactions";
  return post(url, params);
}

export function getAllNftsOfUser(params) {
  const url = "/allnftsofuser";
  return post(url, params);
}

export function getAllNftTransactionsFromToUser(params) {
  const url = "/allnftstransactionsofuser";
  return post(url, params);
}

export function getStakesOfOwner(params) {
  const url = "/stakesofowner";
  return post(url, params);
}

export function getStakesOfValidator(params) {
  const url = "/stakesofvalidator";
  return post(url, params);
}

export function getStakeInfo(params) {
  const url = "/stakeinfo";
  return post(url, params);
}

export function getValidators(params) {
  const url = "/validators";
  return post(url, params);
}

export function getValidatorinfo(params) {
  const url = "/validatorinfo";
  return post(url, params);
}

export function getProposes(params) {
  const url = "/proposes";
  return post(url, params);
}

export function getProposeOfUser(params) {
  const url = "/proposesofuser";
  return post(url, params);
}

export function getProposeInfo(params) {
  const url = "/proposeinfo";
  return post(url, params);
}

export function getContractEvents(params) {
  const url = "/eventsofcontract";
  return post(url, params);
}

export function getERC20s(params) {
  const url = "/prc20s";
  return post(url, params);
}

export function getERC20(params) {
  const url = "/prc20";
  return post(url, params);
}

export function getERC20Txs(params) {
  const url = "/prc20transactions";
  return post(url, params);
}

export function getERC20Holders(params) {
  const url = "/prc20holders";
  return post(url, params);
}

export function getERC20HolderInfo(params) {
  const url = "/prc20holderinfo";
  return post(url, params);
}

export function getAllERC20TransactionsFromToUser(params) {
  const url = "/allprc20transactionsofuser";
  return post(url, params);
}

export function getERC1155s(params) {
  const url = "/erc1155s";
  return post(url, params);
}

export function getERC1155(params) {
  const url = "/erc1155";
  return post(url, params);
}

export function getERC1155Txs(params) {
  const url = "/erc1155transactions";
  return post(url, params);
}

export function getERC1155Holders(params) {
  const url = "/erc1155holders";
  return post(url, params);
}

export function getERC1155Users(params) {
  const url = "/erc1155users";
  return post(url, params);
}

export function getERC1155TokenInfo(params) {
  const url = "/erc1155tokeninfo";
  return post(url, params);
}

export function getERC1155TokenTx(params) {
  const url = "/erc1155tokentransactions";
  return post(url, params);
}

export function getChainStatistics() {
  const url = "/getchainstatistics";
  return get(url);
}
