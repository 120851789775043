/* eslint-disable */
import axios from 'axios';

axios.defaults.timeout = 20000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function get(url) {
  let config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(axios.defaults.baseURL + url, config)
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          console.log(err);
        },
      )
      .catch((err) => {
        console.log(err);
      });
  });
}

export function post(url, param) {
  let config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(axios.defaults.baseURL + url, param, config)
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err)
          console.log(err);
        },
      )
      .catch((err) => {
        console.log(err);
      });
  });
}
